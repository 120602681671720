import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tohtml'
})
export class ToHtmlPipe implements PipeTransform {
  transform(value) {
    return value.replace(/\n/g, '<br/>')
  }

}
