const files = [
  // Nest Entity
  {
    platform: 'nest',
    type: 'entity',
    name: 'x-x.entity.ts',
    folder: 'x-xs/entities'
  },
  // Nest DTOs
  {
    platform: 'nest',
    type: 'create-dto',
    name: 'create-x-x.dto.ts',
    folder: 'x-xs/DTOs'
  },
  {
    platform: 'nest',
    type: 'update-dto',
    name: 'update-x-x.dto.ts',
    folder: 'x-xs/DTOs'
  },
  // Nest Controller, Module & Service
  {
    platform: 'nest',
    type: 'controller',
    name: 'x-xs.controller.ts',
    folder: 'x-xs'
  },
  {
    platform: 'nest',
    type: 'module',
    name: 'x-xs.module.ts',
    folder: 'x-xs'
  },
  {
    platform: 'nest',
    type: 'service',
    name: 'x-xs.service.ts',
    folder: 'x-xs'
  },
  // Angular Model
  {
    platform: 'angular',
    type: 'model',
    name: 'x-x.model.ts',
    folder: 'models'
  },
  // Angular Objects Table
  {
    platform: 'angular',
    type: 'objects.html',
    name: 'x-xs.component.html',
    folder: 'x-xs'
  },
  {
    platform: 'angular',
    type: 'objects.scss',
    name: 'x-xs.component.scss',
    folder: 'x-xs'
  },
  {
    platform: 'angular',
    type: 'objects.ts',
    name: 'x-xs.component.ts',
    folder: 'x-xs'
  },
  // Angular Object Modal
  {
    platform: 'angular',
    type: 'object.html',
    name: 'x-x.component.html',
    folder: 'x-xs/x-x'
  },
  {
    platform: 'angular',
    type: 'object.scss',
    name: 'x-x.component.scss',
    folder: 'x-xs/x-x'
  },
  {
    platform: 'angular',
    type: 'object.ts',
    name: 'x-x.component.ts',
    folder: 'x-xs/x-x'
  },
  // Angular Module & Service
  {
    platform: 'angular',
    type: 'module',
    name: 'x-xs.module.ts',
    folder: 'x-xs'
  },
  {
    platform: 'angular',
    type: 'service',
    name: 'x-xs.service.ts',
    folder: 'x-xs'
  },

  // Sequelize
  {
    platform: 'sequelize',
    type: 'controller',
    name: 'x-xs.js',
    folder: 'controllers'
  },
  {
    platform: 'sequelize',
    type: 'model',
    name: 'x-x.js',
    folder: 'models'
  },
  {
    platform: 'sequelize',
    type: 'routes',
    name: 'index.js',
    folder: 'routes'
  },

];

export default files;