import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient) { }

  getFileContent(file) {
    return this.http.get(file, { responseType: 'text' }).toPromise();
  }
}
