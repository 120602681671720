import { Component } from '@angular/core';
import { saveAs } from '@progress/kendo-file-saver';
import * as JSZip from 'jszip';
import Pluralize from 'pluralize';
import { AppService } from './app.service';
import files from './filesStructure';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  query;
  loading = false;

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.query = localStorage.getItem('sis-copilot-query');
  }

  async convertScript() {
    localStorage.setItem('sis-copilot-query', this.query);
    const exclusions = ['id', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'deletedAt', 'deletedBy',];

    // Removes empty lines
    this.query = this.query.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");

    const tables = this.query.split(';').filter(element => element);
    const objects = [];
    const relationTableNames = [];

    // Format tables and their attributes into arrays
    tables.map(tableAttributes => {
      const firstQuoteIndex = tableAttributes.indexOf('`');
      const secondQuoteIndex = tableAttributes.indexOf('`', firstQuoteIndex + 1);
      const rawAttributes = tableAttributes.slice(tableAttributes.indexOf('(') + 1, tableAttributes.indexOf(';')).split('\n');

      rawAttributes.shift();
      rawAttributes.pop();

      // Format object name
      const table = tableAttributes.slice(firstQuoteIndex + 1, secondQuoteIndex);

      const object = {
        pascalSingular: table,
        pascalPlural: Pluralize(table, 2),
        camelSingular: table[0].toLowerCase() + table.substring(1),
        camelPlural: Pluralize(table[0].toLowerCase() + table.substring(1), 2),
        kebabSingular: table.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase(),
        kebabPlural: Pluralize(table.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase(), 2),
        checks: null,
        attributes: null,
        addRelation: [],
      }

      // Format object attributes
      const attributes = rawAttributes.map(a => this.formatAttribute(a)).filter(a => a !== null);

      // Combine fk field with its relation
      if (attributes.some(a => a.relationWith !== "")) {
        const relations = attributes.filter(a => a.relationWith !== "");

        relations.forEach(relation => {
          const foreignKeyIndex = attributes.findIndex(attr => attr.name === relation.name && attr.relationWith === "");
          const relationIndex = attributes.findIndex(attr => attr.name === relation.name && attr.relationWith !== "");
          attributes[foreignKeyIndex].relationWith = relation.relationWith;

          relationTableNames.push({
            'manyToOneSingular': object.pascalSingular,
            'manyToOnePlural': object.pascalPlural,
            'oneToMany': relation.relationWith,
          });
          attributes.splice(relationIndex, 1);
        });
      }

      object.attributes = attributes.filter(a => !exclusions.includes(a.name) && !a.name.includes('FK_') && !a.name.includes('fk_'));
      objects.push(object);
    });

    relationTableNames.forEach(relation => {
      const index = objects.findIndex((obj => obj.pascalSingular === relation.oneToMany));
      if (index !== -1) {
        objects[index].addRelation.push(relation);
      }
    });

    // Create requested files
    const jszip = new JSZip();
    await Promise.all(objects.map(object => {
      for (let file of files) {
        if (!(file.platform == 'sequelize' && file.type == 'routes')) {
          const script = this.replaceFileContent(object, file.platform, file.type);
          const fileName = this.replaceAlias(object, file.name);
          const folder = file.folder ? this.replaceAlias(object, file.folder) + '/' : '';
          jszip.file(file.platform + '/' + folder + fileName, script);
        }
      }
    }));

    // Create file for routes in sequelize
    const scriptRoutes = await this.replaceFileContent(objects, 'sequelize', 'routes');
    jszip.file('sequelize/routes/index.js', scriptRoutes);

    // Generate zip
    const content = await jszip.generateAsync({ type: 'blob' });
    saveAs(content, 'tables.zip');
  }

  formatAttribute(string) {
    const firstQuoteIndex = string.indexOf('`');
    const secondQuoteIndex = string.indexOf('`', firstQuoteIndex + 1);
    const attribute = {
      name: string.slice(firstQuoteIndex + 1, secondQuoteIndex),
      details: string.slice(secondQuoteIndex + 1)?.toLowerCase(),
      type: null,
      tableType: null,
      inputType: null,
      sequelizeType: null,
      check: null,
      isOptional: !string.slice(secondQuoteIndex + 1).includes('NOT NULL'),
      relationWith: ''
    }

    // Define type & check
    if (attribute.details.includes('varchar') || attribute.details.includes('text')) {
      attribute.type = 'string';
      attribute.tableType = 'text';
      attribute.sequelizeType = 'STRING';
      attribute.inputType = attribute.details.includes('text') ? 'textarea' : attribute.name.includes('email') ? 'email' : attribute.name.includes('password') ? 'password' : 'text';
      attribute.check = 'IsString';
    } else if (attribute.details.includes('date') || attribute.details.includes('datetime')) {
      attribute.type = 'Date';
      attribute.tableType = 'date';
      attribute.sequelizeType = 'DATE';
      attribute.inputType = 'date';
      attribute.check = 'IsDate';
    } else if (attribute.details.includes('tinyint')) {
      attribute.type = 'boolean';
      attribute.tableType = 'check';
      attribute.sequelizeType = 'TINYINT';
      attribute.inputType = 'checkbox';
      attribute.check = 'IsInt';
    } else if (attribute.details.includes('int') || attribute.details.includes('decimal')) {
      attribute.type = 'number';
      attribute.tableType = 'number';
      attribute.sequelizeType = 'INTEGER';
      attribute.inputType = 'number';
      attribute.check = 'IsInt';
    } else if (attribute.details.includes('FOREIGN KEY')) {
      let name = attribute.details.match('(`[A-Za-z]*`)')[0];
      name = name.substring(1, name.length - 1);
      let relativeTable = attribute.details.match(' `[A-Za-z]*` ')[0];
      relativeTable = relativeTable.substring(2, relativeTable.length - 2);
      attribute.name = name;
      attribute.type = 'number';
      attribute.check = 'isInt';
      attribute.relationWith = relativeTable;
    } else if (attribute.details.includes('enum') || attribute.details.includes('text')) {
      attribute.type = 'string';
      attribute.tableType = 'text';
      attribute.sequelizeType = 'STRING';
      attribute.inputType = 'text';
      attribute.check = 'IsString';
    } else if (attribute.name.includes('fk_') && attribute.type == null) {
      return null;
    }

    return attribute;
  }

  async replaceFileContent(object, platform, type) {
    // Stringify attributes based on type
    let stringifyAttributes = '';
    let stringifyImports = '';
    let stringifyFunctions = '';
    let stringifyVariables = '';
    let stringifyFunctionCalls = '';
    let checks = [];
    let controllers = '';
    let query = '';

    if (object.length && type === 'routes') {
      for (let o of object) {
        controllers += `const ${o.camelSingular} = require('../controllers/${o.kamelPlural}');\n`
        stringifyAttributes += `// ${o.camelSingular.toUpperCase()}\nrouter.route('/${o.camelPlural}').get(isAuth, ${o.camelSingular}.get${o.pascalPlural});\nrouter.route('/${o.camelPlural}').post(isAuth, ${o.camelSingular}.create${o.pascalSingular});\nrouter.route('/${o.camelPlural}').put(isAuth, ${o.camelSingular}.update${o.pascalSingular});\nrouter.route('/${o.camelPlural}').delete(isAuth, ${o.camelSingular}.delete${o.pascalSingular});\n\n`
      }
    } else {

      query += `SELECT
          ${object.camelSingular[0]}.id,\n\t\t\t\t\t`;

      if (platform === 'sequelize' && type === 'model') {
        stringifyAttributes += `id: {
        type: DataTypes.INTEGER,
        primaryKey: true,
        autoIncrement: true
      },\n\t\t\t`;
      };

      for (let attribute of object.attributes) {
        if (platform === 'nest' && type === 'entity') {
          stringifyAttributes += `@Column()\n` + `${attribute.name}: ${attribute.type};\n\n`;
        }
        else if (platform === 'nest' && type === 'create-dto') {
          stringifyAttributes += `@${attribute.check}()\n` + (attribute.isOptional ? `@IsOptional()\n` : ``) + `${attribute.name}: ${attribute.type};\n\n`;
          checks.push(attribute.check);
        }
        else if (platform === 'angular' && type === 'model') {
          stringifyAttributes += `${attribute.name}` + (attribute.isOptional ? `?` : ``) + `: ${attribute.type};\n`;
        }
        else if (platform === 'angular' && type === 'objects.ts') {
          stringifyAttributes += `{ type:"${attribute.tableType}", name: "${attribute.name}", label: "${attribute.name}" },\n\t\t`;
        }
        else if (platform === 'angular' && type === 'object.ts') {

          if (attribute.relationWith && attribute.name.substring(attribute.name.length - 2) === 'Id') {
            const nameSingularLower = attribute.relationWith.toLowerCase();
            const namePluralLower = Pluralize(nameSingularLower);
            const nameSingularCapit = attribute.relationWith;
            const namePluralCapit = Pluralize(nameSingularCapit);

            stringifyImports += `import { ${nameSingularCapit} } from '../../models/${nameSingularLower}.model';\n`
            stringifyVariables += `${namePluralLower}: ${nameSingularCapit}[] = []; \n\t`
            stringifyFunctionCalls += `await this.load${namePluralCapit}(); \n\t\t\t`
            stringifyFunctions += `
            async load${namePluralCapit}() {
              try {
                this.${namePluralLower} = await this.xxxsService.get${namePluralCapit}();
              } catch (err) {
                console.log(err);
              }
            }`
          }
        }
        else if (platform === 'angular' && type === 'service') {

          if (attribute.relationWith && attribute.name.substring(attribute.name.length - 2) === 'Id') {
            const nameSingularLower = attribute.relationWith.toLowerCase();
            const namePluralLower = Pluralize(nameSingularLower);
            const nameSingularCapit = attribute.relationWith;
            const namePluralCapit = Pluralize(nameSingularCapit);

            stringifyImports += `import { ${nameSingularCapit} } from '../../models/${nameSingularLower}.model';\n`
            stringifyFunctions += `
            // ${namePluralCapit}

            get${namePluralCapit}(): Promise<${nameSingularCapit}[]> {
            return lastValueFrom(this.http.get('${namePluralLower}')).then((${namePluralLower}: any[]) => ${namePluralLower}.map(${nameSingularLower} => new ${nameSingularCapit}(${nameSingularLower}))) as Promise<${nameSingularCapit}[]>;
            }\n`
          }
        }
        else if (platform === 'angular' && type === 'object.html') {
          const nameSingular = attribute.name.substring(0, attribute.name.length - 2);
          const namePlural = nameSingular[nameSingular.length - 1] == 'y' ? nameSingular.substring(0, nameSingular.length - 1) + 'ies' : nameSingular + 's';

          stringifyAttributes +=
            ` <div class="row">
            <div class="` + (attribute.inputType == 'checkbox' ? "field checkbox" : "field")
            + `">
            `
            + (attribute.inputType !== 'checkbox' ? (` <div class="labels"> 
              <label for="${attribute.name}">` + (attribute.relationWith && attribute.name.substring(attribute.name.length - 2) === 'Id' ? `${attribute.relationWith}` : `${(attribute.name[0].toUpperCase() + attribute.name.substring(1)).split(/(?=[A-Z])/).join(' ')}`) + (attribute.isOptional ? `` : `*`) + `</label>
              </div>
              `) : ``)
            + (attribute.inputType == 'textarea' ?
              `<textarea class="form-control" type="text" [(ngModel)]="xxx.${attribute.name}" name="${attribute.name}" #${attribute.name}="ngModel" ` + (attribute.isOptional ? `` : `required`) + `> </textarea>
            ` : attribute.relationWith && attribute.name.substring(attribute.name.length - 2) === 'Id' ?
                ` <app-dropdown [items]="${namePlural}" [attributes]="['name']" [(ngModel)]="xxx.${attribute.name}" name="${attribute.name}" #${attribute.name}="ngModel"` + (attribute.isOptional ? `` : ` required`) + `> 
            </app-dropdown>
            ` :
                (attribute.inputType === 'checkbox' ? `\t` : ``) + `<input class="form-control" type="${attribute.inputType}" [(ngModel)]="xxx.${attribute.name}" name="${attribute.name}" #${attribute.name}="ngModel"` + (attribute.inputType === "email" ? ` email` : ``) + (attribute.name === "phone" ? ` pattern="'[- +()0-9]+'"` : ``) + (attribute.isOptional ? `` : ` required`) + `>
            `)
            + (attribute.inputType == 'checkbox' ? `  <label for="${attribute.name}">${(attribute.name[0].toUpperCase() + attribute.name.substring(1)).split(/(?=[A-Z])/).join(' ')}</label>
            ` : ``)
            + `</div>
          </div>
          `;
        }
        else if (platform === 'sequelize' && type === 'model') {
          stringifyAttributes += `${attribute.name}: DataTypes.${attribute.sequelizeType},\n\t\t\t`;
        }
        else if (platform === 'sequelize' && type === 'controller') {
          query += `${object.camelSingular[0]}.${attribute.name},\n\t\t\t\t\t`;
        }
      }

      if (platform === 'nest' && type === 'entity') {
        if (object.addRelation.length > 0) {
          object.addRelation.forEach(relation => {
            const relationSingular = relation.manyToOneSingular;
            const relationPlural = relation.manyToOnePlural.toLowerCase();

            stringifyAttributes += `@OneToMany(() => ${relationSingular}, ${relationSingular.toLowerCase()} => ${relationSingular.toLowerCase()}.${object.camelSingular})\n${relationPlural}!: ${relationSingular}[];\n\n`;

          })
        }

        const relations = object.attributes.filter(attr => attr.relationWith !== "");
        if (relations.length > 0) {
          relations.forEach(relation => {
            const relationTableName = relation.relationWith;
            stringifyAttributes += `@ManyToOne(() => ${relationTableName}, ${relationTableName.toLowerCase()} => ${relationTableName.toLowerCase()}.${object.camelPlural})\n@JoinColumn({ name : '${relation.name}' })\n${relationTableName.toLowerCase()}: ${relationTableName};\n\n`
          });
        }
      }

      if (platform === 'sequelize' && type === 'controller') {
        // Remove last \t
        query = query.substring(0, query.length - 1);

        query += `FROM ${object.pascalSingular} ${object.camelSingular[0]}
        WHERE ${object.camelSingular[0]}.id \${id}
        AND ${object.camelSingular[0]}.deletedAt IS NULL
        ORDER BY ${object.camelSingular[0]}.id`;
      }
    }

    // Stringify checks
    checks = [...new Set(checks)];
    let stringifyChecks = JSON.stringify(checks) as any;
    stringifyChecks = stringifyChecks.replace(/[\[\]\"]/g, "");

    // Get file
    let filePath = './assets/files/' + platform + '/' + type + '.txt';
    let content = await this.appService.getFileContent(filePath) as any;

    // Replace aliases
    content = content.split('{{ attributes }}').join(stringifyAttributes);
    content = content.split('{{ imports }}').join(stringifyImports);
    content = content.split('{{ variables }}').join(stringifyVariables);
    content = content.split('{{ functions }}').join(stringifyFunctions);
    content = content.split('{{ functionCalls }}').join(stringifyFunctionCalls);
    content = content.split('{{ checks }}').join(stringifyChecks);
    content = content.split('{{ query }}').join(query);
    content = content.split('{{ controllers }}').join(controllers);
    content = this.replaceAlias(object, content);

    return content;
  }

  replaceAlias(object, word) {
    word = word.split('XXXs').join(object.pascalPlural);
    word = word.split('XXX').join(object.pascalSingular);
    word = word.split('xxxs').join(object.camelPlural);
    word = word.split('xxx').join(object.camelSingular);
    word = word.split('x-xs').join(object.kebabPlural);
    word = word.split('x-x').join(object.kebabSingular);
    return word;
  }
}
